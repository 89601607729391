<template>
  <div class="wwa_box main">
    <div class="banner_box">
      <div class="swiper_box">
        <img src="@/assets/images/banner5.jpg" />
      </div>
    </div>
    <div class="container content">
      <ul class="list_">
        <li>
          <div class="logo"><img src="@/assets/images/logo4.png" /></div>

          <div class="con">
            <div class="title_">ECI Academy</div>
            <div class="text_">
              ECI Academy is an innovation management school founded by IECIA,
              to train senior management talents and business leaders in
              business innovation. The ECI Academy is the first in the world to
              propose a 5-in-1 innovative curriculum system and scenario-based
              case teaching method of "technology innovation + capital operation
              + brand aesthetic + creative communication + business management"
              to achieve the combination of "technical product commercialization
              + creative brand humanity", creating the leader of the digital
              economy-chief innovation officer, to achieve the success of
              business innovation.
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Introduction',
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/whoweare.scss';
</style>
